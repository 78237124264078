<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      scrollable
      persistent
      max-width="480px"
      v-if="resolutionScreen >= 500"
    >
      <v-card>
        <v-card-title
          >{{ $t("checkpassword.forget") }}<v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35">mdi-key</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <!-- <v-list>
            <v-list-item>
              <v-list-item-icon>
                  <v-list-item-title>{{$t('checkpassword.passwordotp')}} :</v-list-item-title>
              </v-list-item-icon>
                  <v-chip :color="Reference !== '' ? 'gray' : 'white'">{{Reference}}</v-chip>
              <v-list-item-action>
              </v-list-item-action>
            </v-list-item>           
              <div class="text-center">
                <div class="">
                  <v-btn
                    color="secondary"
                    dark
                    @click="getrequestOTP()"
                  >
                    ขอรหัส OTP / Request OTP
                  </v-btn>
                </div>
              </div>
          </v-list> -->
          <!-- <v-list>
                <v-list-item>
                    <v-list-item-icon>
                        <v-list-item-title>{{$t('checkpassword.password')}} :</v-list-item-title>
                    </v-list-item-icon>
                    <v-list-item-content>
                    <v-text-field :value="Reference" outlined dense v-model="password" :type="showpassword ? 'text' : 'password'" :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showpassword = !showpassword"></v-text-field>
                    </v-list-item-content>
                    <v-list-item-action>

                    </v-list-item-action>
                </v-list-item>
            </v-list> -->
          <!-- <v-row dense justify="end" class="elevation-0 mt-2" >
            <v-col cols="12" lg="1"  class="mt-2">
            </v-col>
            <v-col cols="12" lg="3" md="3" sm="3" xs="3" >
              <v-btn
                  :color="color.theme"
                  dark
                  @click="getrequestOTP()"
                >
                 {{$t('checkpassword.requestotp')}}
              </v-btn>
            </v-col>
          </v-row> -->
          <v-row dense justify-space-around class="elevation-0">
            <v-col cols="12" lg="4" class="mt-3">
              <v-list-item-title
                >{{ $t("checkpassword.passwordotp") }} :</v-list-item-title
              >
            </v-col>
            <v-col cols="6" lg="4" class="mt-2">
              <v-chip>{{
                Reference === ""
                  ? $t("checkpassword.clickrequestotp")
                  : Reference
              }}</v-chip>
            </v-col>
            <v-col cols="6" lg="4" class="mt-2 text-right">
              <v-btn :color="color.theme" dark @click="getrequestOTP()">
                {{ $t("checkpassword.requestotp") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row dense justify="center" class="elevation-0">
            <v-col cols="12" lg="4" class="mt-4">
              <v-list-item-title
                >{{ $t("checkpassword.password") }} :</v-list-item-title
              >
            </v-col>
            <v-col cols="12" lg="8" class="mt-2">
              <v-text-field
                dense
                :value="Reference"
                outlined
                v-model="otp"
                :type="showpassword ? 'text' : 'password'"
                :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showpassword = !showpassword"
                autocomplete="new-password"
              ></v-text-field>
            </v-col>
            <!-- text
            prominent -->
            <v-alert
              v-model="alertmessage"
              type="success"
              v-if="alertmessage === true && status === 'OK'"
            >
              <span style="font-size:95%">{{ message }}</span>
            </v-alert>
            <v-alert v-model="alertmessage" text prominent type="error" v-else>
              <span style="font-size:95%">{{ message }}</span>
            </v-alert>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closedialog()" color="red" outlined dark>{{
            $t("checkpassword.cancel")
          }}</v-btn>
          <v-btn :color="color.theme" :dark="color.darkTheme" @click="checkotp()">{{
            $t("checkpassword.ok")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="show"
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
      v-else
    >
      <v-card>
        <v-card-text class="pa-5">
          <v-layout class="pt-2 pb-4">
            <v-flex d-flex justify-center class="ml-8">
              <span :style="headerPage">{{ $t("checkpassword.forget") }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon :color="color.theme" @click="closedialog()"
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
          <span :style="titleText"
            >{{ $t("checkpassword.passwordotp") }} :</span
          >
          <br />
          <v-chip class="mr-4">{{
            Reference === "" ? $t("checkpassword.clickrequestotp") : Reference
          }}</v-chip>
          <v-btn :color="color.theme" dark @click="getrequestOTP()">
            {{ $t("checkpassword.requestotp") }}
          </v-btn>
          <br />
          <span :style="titleText">{{ $t("checkpassword.password") }}</span>
          <v-text-field
            class="mt-1 textFieldClass"
            :color="color.theme"
            outlined
            dense
            :value="Reference"
            v-model="otp"
            :type="showpassword ? 'text' : 'password'"
            :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
            height="35px"
            @click:append="showpassword = !showpassword"
            autocomplete="new-password"
            persistent-hint
          ></v-text-field>
          <v-alert
            v-model="alertmessage"
            type="success"
            v-if="alertmessage === true && status === 'OK'"
          >
            <span style="font-size:85%">{{ message }}</span>
          </v-alert>
          <v-alert v-model="alertmessage" text prominent type="error" v-else>
            <span style="font-size:85%">{{ message }}</span>
          </v-alert>
          <div class="text-center">
            <v-btn
              class="mr-6"
              color="red"
              outlined
              @click="closedialog()"
              >{{ $t("checkpassword.cancel") }}</v-btn
            >
            <v-btn
              class="elevation-0"
              :color="color.theme"
              :dark="color.darkTheme"
              @click="checkotp()"
              >{{ $t("checkpassword.ok") }}</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <newpassword
      :show="opennewpassword"
      @closedialog="close()"
      :filedata="filedata"
    />
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
const newpassword = () => import("@/components/optional/dialog-newpassword");
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  data: function() {
    return {
      status: "",
      message: "",
      password: "",
      otp: "",
      opennewpassword: false,
      showpassword: false,
      alertmessage: false,
    };
  },
  components: { newpassword },
  props: ["show", "Reference", "filedata"],
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    colorProgress() {
      return "color:" + "white" + ";";
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // style อักษร header ของ mobile
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // style อักษรใน dialog ของ mobile
    titleText() {
      return "color:black; font-size: 14px; line-height: 28px;";
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 600;
      } else {
        return 346;
      }
    },
  },
  methods: {
    closedialog() {
      this.$emit("closedialog");
      this.otp = "";
      this.alertmessage = false;
    },
    async getrequestOTP() {
      if (this.filedata.file_type === "folder") {
        let payload = {
          folder_id: this.filedata.file_id,
          account_id: this.dataAccountId,
        };
        let auth = await gbfGenerate.generateToken();
        console.log("payloadfolder", payload);
        await this.axios
          .post(
            process.env.VUE_APP_SERVICE_UPDATE_FOLDER +
              "/api/send_otp/lock_folder",
            payload,
            { headers: { Authorization: auth.code } }
          )
          .then((response) => {
            // console.log("derrrrrrrrrrrr",this.Reference);
            if (response.data.status === "OK") {
              this.status = response.data.status;
              this.Reference = response.data.Reference;
              this.message = response.data.message;
              this.message = this.message.replace("Send OTP to mail", "");
              this.message = this.message.replace("Success", "");
              this.message =
                this.$t("checkpassword.sendotp") +
                this.message +
                this.$t("checkpassword.success");
              this.alertmessage = true;
              console.log("folder", response.data.message);
              //  Toast.fire({
              //     icon: "success",
              //     title: response.data.message
              //   });
            } else {
              this.status = response.data.status;
              this.message = this.$t("checkpassword.sendotpfailed");
              this.alertmessage = true;
            }
          });
      } else {
        let payload = {
          file_id: this.filedata.file_id,
          account_id: this.dataAccountId,
        };
        console.log("payloadfile", payload);
        let auth = await gbfGenerate.generateToken();
        await this.axios
          .post(
            process.env.VUE_APP_SERVICE_UPDATE_FILE + "/api/send_otp/lock_file",
            payload,
            { headers: { Authorization: auth.code } }
          )
          .then((response) => {
            console.log("fileeeeeeee", response);
            if (response.data.status === "OK") {
              this.status = response.data.status;
              this.Reference = response.data.Reference;
              this.message = response.data.message;
              this.message = this.message.replace("Send OTP to mail", "");
              this.message = this.message.replace("Success", "");
              this.message =
                this.$t("checkpassword.sendotp") +
                this.message +
                this.$t("checkpassword.success");
              this.alertmessage = true;
              console.log("file", this.Reference);
              //   Toast.fire({
              //   icon: "success",
              //   title: response.data.message
              // });
            } else {
              this.status = response.data.status;
              this.message = this.$t("checkpassword.sendotpfailed");
              this.alertmessage = true;
            }
          });
      }
    },
    close() {
      this.opennewpassword = false;
      this.$emit("closedialog");
    },
    async checkotp() {
      let payload = {
        otp: this.otp,
        Reference: this.Reference,
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/check_otp",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          console.log("resss", response);
          if (response.data.status === "OK") {
            this.$emit("closedialog");
            this.otp = "";
            this.opennewpassword = true;
            this.alertmessage = false;
          } else {
            Toast.fire({
              icon: "error",
              title: this.$t("checkpassword.passwordincorrect"),
            });
            this.otp = "";
          }
        });
    },
  },
};
</script>
<style>
.v-text-field.textFieldClass .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.v-input.textFieldClass input {
  font-size: 14px;
}
</style>
